$errorBtn : #E0144C;
$errorBtnHover : #b10835;
$secondaryBtn : #3866A8;
$warningBtn : #FF731D;
$purpleBtn : #3F0071;

$lightText : #CED4D7;
$darkText : #696D6F;
$errorText : #E0144C;
$purpleText: #3F0071;

$bgDark : #121212;
$bgLight: #CED4D7


