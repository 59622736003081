@import "../../Styles/variables";

button {
  transition: 0.8s ease-in-out;
  text-decoration: none;
}
.btn-red {
  background: $errorBtn;
  color: $lightText;
  &:hover {
    background: $errorBtnHover;
    transition: 0.2s ease-in-out;
  }
}

.btn-purple {
  background: $purpleText;
  color: $lightText;
}

.btn-orange {
  background: #ff731d;
  color: $lightText;

  &:hover {
    background: #a44700;
    transition: 0.5s ease-in-out;
  }
}

.btn-blue {
  background: #3866a8;
  color: $lightText;
  &:hover {
    background: #0e428a;
    transition: 0.5s ease-in-out;
  }
}

.btn-none {
  width: 144px;
  height: 42px;
  border-radius: 50px;
  border: none;
  font-size: 24px;
  display: flex;
  background: transparent;
  color: $lightText;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: none;
  gap: 6px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $darkText;
  }
}
.btn-small {
  width: 122px;
  height: 42px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
}
.btn-medium {
  width: 144px;
  height: 42px;
  border-radius: 50px;
  border: none;
  font-size: 24px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
}
// .btn-small{
//     width: 144px;
//     height: 42px;
//     border-radius: 50px;
//     border: none;
//     font-size:24px ;
//     display: flex;
//     justify-content: center;
//     align-items: center;

// }
.btn-large {
  width: 100%;
  height: 115px;
  border-radius: 32px;
  border: none;
  font-size: 38px;
  display: flex;
  transition: 0.8s ease-in-out;
  transform: translateX(0px);
  overflow: hidden;
  text-decoration: none;
  justify-content: space-between;
  padding: 40px !important;
  align-items: center;
  cursor: pointer;
  outline: none;
  margin-bottom: 50px;

  // transform:translateX(10px);

  // left: -100%;
}
