// @import "../../Styles/variables";

// .loading {
//   z-index: 999;
//   position: fixed;
//   //  background-image: url('./Img/website-background-image-size-psd-vector-photo.jpg');
//   height: 100vh;
//   width: 100vw;
//   color: white;
//   display: flex;
//   //  background: linear-gradient(90deg, rgba(2,0,36,1) 50%, rgba(255,255,255,1) 50%);
//   background: black;
//   transform: translatex(0px);
//   justify-content: center;
//   transition: 2s;
//   font-size: large;
//   align-items: center;
//   font-family: "IBM Plex Mono", monospace;
//   font-family: "Titan One", cursive;
// }

// .target {
//   font-size: 60px;
//   font-weight: 600;
//   color: $lightText;
// }

// .container {
//   background: $bgDark;
//   color: $lightText;
//   display: grid;
//   //  height: 200vh;
//   width: 100%;

//   .home-container {
//     display: grid;
//     align-items: center;
//     grid-template-columns: 1fr 1fr;
//     width: 100%;
//     //  height: 100vh;
//     @media screen and (max-width: 640px) {
//       //do Smth
//       grid-template-columns: 1fr;
//     }

//     padding: 20px;
//     .home-left {
//       // top: 0;
//       //    background:red;
//       display: grid;
//       position: fixed;
//       width: 50%;
//       justify-content: center;
//       // grid-template-rows: 1fr;

//       height: 100vh;
//       align-items: center;
//       @media screen and (max-width: 640px) {
//         //do Smth
//         position: relative;
//       }
//       .content-left {
//         max-width: 500px;

//         .title {
//           color: $errorText;
//           font-size: 32px;
//           margin-top: 16px;
//         }

//         h1 {
//           font-size: 52px;
//           margin-top: 16px;
//         }

//         .h2 {
//           font-size: 32px;
//           margin-top: 16px;
//         }

//         .desc {
//           color: $darkText;
//           font-size: 28px;
//           margin-top: 16px;
//           // margin-left: 106px;
//           width: 50%;
//         }

//         button {
//           margin-top: 40px;
//         }
//       }

//       .navigation {
//         display: flex;
//         position: absolute;
//         margin-top: 120px;
//         // transform: translateY(100%);
//       }
//     }

//     .home-right {
//       display: grid;
//       justify-content: center;
//       margin-top: 182px;
//       // background: $bgDark;
//       // height: 100vh;
//       // background: blue;
//       // align-items: start;

//       .content-right {
//         // background: $bgDark;
//         width: 400px;
//         .card-portfolio {
//           border: solid 4px $purpleBtn;
//           height: 237px;
//           border-radius: 20px;
//           display: grid;
//           padding: 20px;
//           // justify-content:center;
//           align-items: center;

//           .h2 {
//             color: $errorText;
//             text-transform: capitalize;
//           }
//           button {
//             margin-top: 20px;
//           }
//         }

//         .card-content {
//           margin-top: 40px;

//           .title-content {
//             padding: 20px;
//             color: $darkText;
//             font-size: 22px;
//             p {
//               color: $lightText;
//             }
//           }

//           .btn {
//             transition: 0.8s ease-in-out;
//             &:hover {
//               background: red;
//               transition: 0.8s ease-in-out;
//             }
//           }
//           // .btn-wrapp{
//           //     width: 90%;
//           //     overflow: hidden;
//           //     display: flex;
//           //     justify-content: center;
//           //     .btn{
//           //         background: $errorBtn;
//           //         // position: absolute;
//           //         display: flex;
//           //         width: 100%;
//           //         height: 100%;
//           //         // padding: 40px;
//           //         align-items: center;
//           //         justify-content: space-between;
//           //         // transition: transform 0.8s ease-in-out;
//           //         transform: translateX(-20%);
//           //         overflow: hidden;
//           //         // transform: translateX(0px);
//           //         // &:hover{
//           //         //     transform: translateX(20px);
//           //         // }
//           // }
//           // }
//           .card-wrapp {
//             display: flex;
//             align-items: center;
//             margin-bottom: 40px;

//             .icon-card {
//               border: 1px solid $darkText;
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               width: 100px;
//               height: 100px;
//               margin-right: 20px;
//               border-radius: 50%;
//               &:hover {
//                 border: 3px solid $lightText;
//                 transition: 1s all ease;
//                 transform: scale(1.1);
//               }
//             }
//             .card-desc h1 {
//               font-size: 28px;
//               font-weight: 400;
//               color: $lightText;
//             }
//             h2 {
//               font-size: 22px;
//               color: $darkText;
//               font-weight: normal;
//             }
//           }
//         }
//         // }
//       }
//     }
//   }
//   .navigation-home {
//     display: flex;
//     position: fixed;
//     justify-content: center;
//     align-items: center;
//     // background: red;
//     padding: 20px;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     flex-direction: row;
//     gap: 10px;
//     transform: rotate(90deg);
//     // transform: translate(10%,100%);
//   }
// }

@import "../../Styles/variables";

.loading {
  z-index: 999;
  position: fixed;
  //  background-image: url('./Img/website-background-image-size-psd-vector-photo.jpg');
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  //  background: linear-gradient(90deg, rgba(2,0,36,1) 50%, rgba(255,255,255,1) 50%);
  background: black;
  transform: translateY(0px);
  justify-content: center;
  transition: 2s;
  font-size: large;
  align-items: center;
  font-family: "Poppins", sans-serif;
  // font-family: "IBM Plex Mono", monospace;
  // font-family: "Titan One", cursive;
}

.target {
  font-size: 60px;
  font-weight: 600;
  color: $lightText;
}
//page

.container {
  background: $bgDark;
  color: $lightText;
  // display: grid;
  // //  height: 200vh;
  // margin: auto;
  // width: 100%;
  overflow: hidden;
  // margin: 10px;
  padding: 10px;

  .home-container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    @media screen and (max-width: 640px) {
      grid-template-columns: 1fr;
      width: 100%;
      position: relative;
      // justify-self: center;
      // justify-content: center;
      // align-items: center;
      // background: darkolivegreen;
    }

    .home-left {
      // top: 0;
      // background: red;
      display: grid;
      position: fixed;
      width: 50%;
      justify-content: center;
      align-items: center;
      height: 100vh;

      // align-items: center;
      @media screen and (max-width: 600px) {
        width: 100%;
        height: fit-content;
        display: flex;
        position: relative;
        margin-bottom: 50px;
        // justify-content: center;
        // position: absolute;
      }
      .content-left {
        max-width: 500px;
        // background-color: blue;
        // background: red;
        @media screen and (max-width: 640px) {
          //do Smth
          // max-width: 100%;
          height: fit-content;
          width: 100%;
          display: flex;
          flex-direction: column;
          // margin: 10px;
        }

        .title {
          color: $errorText;
          font-size: 32px;
          margin-top: 16px;
          @media screen and (max-width: 640px) {
            font-size: 22px;
          }
        }

        h1 {
          font-size: 52px;
          margin-top: 16px;
          @media screen and (max-width: 640px) {
            font-size: 32px;
          }
        }

        .h2 {
          font-size: 32px;
          margin-top: 16px;
          @media screen and (max-width: 640px) {
            font-size: 28px;
          }
        }

        .desc {
          color: $darkText;
          font-size: 28px;
          margin-top: 16px;
          // margin-left: 106px;
          width: 70%;
          @media screen and (max-width: 640px) {
            width: 100%;
            font-size: 24px;
          }
        }

        button {
          margin-top: 40px;
        }

        // .about-email {
        //   margin: 10px;
        // }
        .navigation {
          display: flex;
          position: absolute;
          // margin-top: 80px;
          transform: translateY(40%);
          @media screen and (max-width: 640px) {
            display: flex;
            position: relative;
            margin-top: 30px;
          }
        }
      }
    }
    // content right

    .home-right {
      display: grid;
      justify-content: center;
      margin-top: 182px;
      // background: $bgDark;
      // height: 100vh;
      // align-items: start;
      @media screen and (max-width: 640px) {
        margin-top: 0px;
      }

      #progress {
        position: fixed;
        top: 20px;
        right: -80px;
        width: 350px;
      }
      .data {
        stroke-width: 15;
        fill: none;
      }
      .text {
        font-family: sans-serif;
        fill: #5f5f5f;
        font-size: 20px;
        font-weight: 600;
        visibility: hidden;
      }

      .content-right {
        // background: $bgDark;
        max-width: 440px; // .card-portfolio {
        //   border: solid 4px $purpleBtn;
        //   // height: 237px;
        //   border-radius: 20px;
        //   display: grid;
        //   padding: 20px;
        //   // justify-content:center;
        //   align-items: center;
        @media screen and (max-width: 640px) {
          width: 100%;
          padding: 10px;
        }

        .h2 {
          color: $errorText;
          text-transform: capitalize;
        }
        button {
          margin-top: 20px;
        }

        .card-information {
          border-bottom: 1px solid $darkText;
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin: 40px 0;

          .title {
            color: $darkText;
          }

          .desc-about {
            margin-left: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
          }
        }

        .card-portfolio {
          border: solid 4px $purpleBtn;
          height: 237px;
          border-radius: 20px;
          display: grid;
          padding: 20px;
          // justify-content:center;
          align-items: center;

          .h2 {
            color: $errorText;
            text-transform: capitalize;
          }
          button {
            margin-top: 20px;
          }
        }

        .card-content {
          margin-top: 40px;

          .title-content {
            padding: 20px;
            color: $darkText;
            font-size: 22px;
            p {
              color: $lightText;
            }
          }

          .btn {
            transition: 0.8s ease-in-out;
            &:hover {
              background: red;
              transition: 0.8s ease-in-out;
            }
          }
          .card-wrapp {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            .icon-card {
              border: 1px solid $darkText;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100px;
              height: 100px;
              margin-right: 20px;
              border-radius: 50%;
              &:hover {
                border: 3px solid $lightText;
                transition: 1s all ease;
                transform: scale(1.1);
              }
            }
            .card-desc h1 {
              font-size: 28px;
              font-weight: 400;
              color: $lightText;
            }
            h2 {
              font-size: 22px;
              color: $darkText;
              font-weight: normal;
            }
          }
        }
        // }
      }
    }
  }
  .navigation-home {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    padding: 20px;
    right: 0;
    top: 0;
    bottom: 0;
    flex-direction: row;
    gap: 20px;
    transform: rotate(90deg);
    @media screen and (max-width: 640px) {
      //do Smth
      display: none;
    }
    .home-navigate {
      background-color: $bgDark;
      border: none;
      // padding: 10px;
      font-size: 18px;
      color: $darkText;
      &:hover {
        color: $lightText;
      }
    }

    .active {
      border-bottom: 3px solid $errorText;
      color: $lightText;
    }

    .wrapp-arrow {
      position: absolute;
      transform: translateX(300px);
      padding: 10px;
      .arrow {
        width: 50px;
        height: 50px;
        padding: 12px;
        border: 1px solid white;
        border-radius: 50%;
        animation: pointDown 2s infinite;
        // transform: translate(0,50%);
      }
    }

    @keyframes pointDown {
      0% {
        transform: translatex(0);
      }
      10% {
        transform: translatex(30px);
      }
      20% {
        transform: translatex(0);
      }
      30% {
        transform: translatex(30px);
      }
      40% {
        transform: translatex(0);
      }
    }

    .about-navigate {
      background-color: $bgDark;
      border: none;
      // padding: 10px;
      font-size: 18px;
      color: $darkText;
      &:hover {
        color: $lightText;
      }
    }
    .active {
      border-bottom: 3px solid $errorText;
    }
  }
}
